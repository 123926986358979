import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { useDispatch, connect } from "react-redux";
import { getUser } from "../../../_actions/user_actions";
import { Formik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment'
import { updateUser, localUpdateUser, resetPassword, deleteUser } from "../../../_actions/user_actions";
import { Form, Input, Button, Popconfirm, Row, Col, message, Card, Tooltip } from 'antd';
import './User.css';

const formItemLayout = {
  labelCol: {
    xs: { span: 44 },
    sm: { span: 10 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 13 },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 10,
    },
    sm: {
      span: 16,
      offset: 10,
    },
  },
};

function UserPage(props) {
  const zeroValue = "0001-01-01T00:00:00Z";
  const dispatch = useDispatch();
  const chosenUser = props.data.user?.chosenUser ?? ["Loading Data"]
  const userData = props.data.user?.userData ?? ["Loading Data"]
  const activeUserMatch = chosenUser._id === userData._id
  const netWorth = chosenUser.netWorth ? chosenUser.netWorth.toLocaleString('en', {useGrouping:true}) : 0
  const handleDelete = () => {
    dispatch(deleteUser({"_id": chosenUser.email})).then(response => {
      props.history.push("/student/users/")
    })
  }

  useEffect(() => {
    const path = props.location.pathname
    let n = path.lastIndexOf('/');
    let short_id = path.substring(n + 1);
    let dataToSubmit = {_id : short_id}
  	dispatch(getUser(dataToSubmit))
  }, [dispatch, props.location.pathname])
  
  return (
    <Formik
      enableReinitialize
      initialValues={{
        email: chosenUser.email,
        lastName: chosenUser.lastName,
        firstName: chosenUser.firstName,
        netWorth: netWorth,
        rank: chosenUser.rank,
        children: chosenUser.children,
        college: chosenUser.college,
        income: chosenUser.income,
        changeCareer: chosenUser.careerTransition,
        tax: chosenUser.taxableIncome,
        password: '',
        confirmPassword: '',
        newJob: chosenUser.collegeEnd !== zeroValue ? chosenUser.collegeEnd :
        chosenUser.transitionEnd !== zeroValue ? chosenUser.transitionEnd : zeroValue,
      }}
      validationSchema={Yup.object().shape({
        firstName: Yup.string()
          .required('First name is required'),
        lastName: Yup.string()
          .required('Last name is required'),
        email: Yup.string()
          .matches(/@/, "Must contain '@' symbol")
          .required('Email is required'),
        password: Yup.string()
          .min(6, 'Password must be at least 6 characters'),
        confirmPassword: Yup.string()
          .oneOf([Yup.ref('password'), null], 'Passwords must match')
      })}

      onSubmit={(values, { setSubmitting }) => {

          let dataToSubmit = {
            email: values.email,
            password: values.password,
            firstName: values.firstName,
            lastName: values.lastName,
            college: values.college,
            careerTransition: values.changeCareer
          };

          dispatch(updateUser(dataToSubmit)).then(response => {
            if (response.payload.role >= 0) {
              message.success("Data has been updated")
              const path = props.location.pathname
              let n = path.lastIndexOf('/');
              let short_id = path.substring(n + 1);
              let dataToSubmit = {_id : short_id}
              dispatch(getUser(dataToSubmit))
            }
            else {
              message.error("Failed to save data")
            }

            values.password = ""
            values.confirmPassword = ""
            setSubmitting(false)
          })
      }}
    >

      {props => {
        const {
          values,
          touched,
          errors,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit
        } = props;

        const handleCollegeToggle = () => {
          values.college = !values.college
          chosenUser.college = values.college
          dispatch(localUpdateUser(chosenUser))
          chosenUser.collegeEnd = 0
        }

        const handleCareerToggle = () => {
          values.changeCareer = !values.changeCareer
          chosenUser.careerTransition = values.changeCareer
          dispatch(localUpdateUser(chosenUser))
        }

        const handleReset = () => {
          dispatch(resetPassword({"_id": chosenUser.email})).then(response => {
            message.success({
              content: response.payload.password,
              style: {
                marginTop: '20vh',
                "font-size": '42px'
              },
              duration: 20
            })
          })
        }

        return (
          <div className="body">
            <div className='form'>
              <h1>User Details</h1>
              <Form {...formItemLayout} onSubmit={handleSubmit} >
                <Row>
                  <Col span={8}>
                    <Form.Item label="First Name">
                      <Input
                        readOnly={!activeUserMatch}
                        id="firstName"
                        placeholder="Enter your first name"
                        type="text"
                        value={values.firstName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={
                          errors.firstName && touched.firstName ? 'text-input error' : 'text-input'
                        }
                      />
                      {errors.firstName && touched.firstName && (
                        <div className="input-feedback">{errors.firstName}</div>
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label="Last Name">
                      <Input
                        readOnly={!activeUserMatch}
                        id="lastName"
                        placeholder="Enter your Last Name"
                        type="text"
                        value={values.lastName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={
                          errors.lastName && touched.lastName ? 'text-input error' : 'text-input'
                        }
                      />
                      {errors.lastName && touched.lastName && (
                        <div className="input-feedback">{errors.lastName}</div>
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label="Email" validateStatus={errors.email && touched.email ? "error" : 'success'}>
                      <Input
                        readOnly
                        id="email"
                        placeholder="Enter your Email"
                        type="email"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={
                          errors.email && touched.email ? 'text-input error' : 'text-input'
                        }
                      />
                      {errors.email && touched.email && (
                        <div className="input-feedback">{errors.email}</div>
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row hidden={userData.role > 0 && chosenUser.role > 0}>
                  <Col span={8}>
                    <Form.Item label="Net Worth">
                      <Input
                        readOnly
                        id="netWorth"
                        placeholder="Here is your net worth"
                        type="float"
                        value={values.netWorth}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label="Rank" validateStatus={errors.rank && touched.rank ? "error" : 'success'}>
                      <Input
                        readOnly
                        id="rank"
                        placeholder="Enter your Email"
                        type="text"
                        value={values.rank}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={
                          errors.rank && touched.rank ? 'text-input error' : 'text-input'
                        }
                      />
                      {errors.rank && touched.rank && (
                        <div className="input-feedback">{errors.rank}</div>
                      )}
                    </Form.Item>
                  </Col>

                  <Col span={8}>
                    <Tooltip title="Income since last tax">
                      <Form.Item hidden={!(activeUserMatch || userData.role !== 0)} label="Taxable Income" validateStatus={errors.tax && touched.tax ? "error" : 'success'}>
                        <Input
                          readOnly
                          id="tax"
                          placeholder="Taxable Income"
                          type="text"
                          value={values.tax}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={
                            errors.tax && touched.tax ? 'text-input error' : 'text-input'
                          }
                        />
                        {errors.tax && touched.tax && (
                          <div className="input-feedback">{errors.tax}</div>
                        )}
                      </Form.Item>
                    </Tooltip>
                  </Col>

                </Row>
                <Row hidden={userData.role > 0 && chosenUser.role > 0}>
                  <Col span={8}>
                    <Form.Item label="Income">
                      <Input
                        readOnly
                        id="income"
                        placeholder="Annual Income"
                        type="int"
                        value={values.income}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label="Change Career" hidden={!activeUserMatch}>
                      <label className="switch">
                        <Popconfirm 
                          disabled={values.changeCareer || chosenUser.collegeEnd !== "0001-01-01T00:00:00Z"} 
                          hidden={!activeUserMatch}
                          onConfirm={handleCareerToggle}
                          title={`Are you sure? You will recieve half income for 4 days and your income will be a random number between ${chosenUser.college ? "208-834" : "104-335"}. This cannot be undone! Don't forget to hit submit.`}
                          okText="Yes"
                          cancelText="No"
                        >
                          <input id="changeCareer" type="checkbox" checked={values.changeCareer} />
                        </Popconfirm>
                        <span className="slider round"></span>
                      </label>
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label="University">
                      <label className="switch">
                        <Popconfirm
                          disabled={values.college || !activeUserMatch || chosenUser.careerTransition}
                          onConfirm={handleCollegeToggle}
                          title="Are you sure? You will recieve half income for 14 days and lose $5000-$8000. Then your income will be a random number between 208-834. This cannot be undone! Don't forget to hit submit."
                          okText="Yes"
                          cancelText="No"
                        >
                          <input id="college" type="checkbox" checked={values.college} />
                        </Popconfirm>
                        <span className="slider round"></span>
                      </label>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={8}>
                    <Form.Item hidden={!activeUserMatch} label="Reset Password" validateStatus={errors.password && touched.password ? "error" : 'success'}>
                      <Input
                        id="password"
                        placeholder="Enter your password"
                        type="password"
                        value={values.password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={
                          errors.password && touched.password ? 'text-input error' : 'text-input'
                        }
                      />
                      {errors.password && touched.password && (
                        <div className="input-feedback">{errors.password}</div>
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item hidden = {!activeUserMatch} label="Confirm" hasFeedback>
                      <Input
                        id="confirmPassword"
                        placeholder="Enter your confirmPassword"
                        type="password"
                        value={values.confirmPassword}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={
                          errors.confirmPassword && touched.confirmPassword ? 'text-input error' : 'text-input'
                        }
                      />
                      {errors.confirmPassword && touched.confirmPassword && (
                        <div className="input-feedback">{errors.confirmPassword}</div>
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item hidden = {(!activeUserMatch && userData.role === 0) || values.newJob === zeroValue} label="New Job" hasFeedback>
                      <Input
                        id="newJob"
                        type="text"
                        value={moment(values.newJob).format('YYYY-MM-DD HH:mm')}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item hidden = {!activeUserMatch} {...tailFormItemLayout}>
                  <Button onClick={handleSubmit} type="primary" disabled={isSubmitting || errors.password || errors.firstName || errors.lastName || errors.email || (values.password && values.password !== values.confirmPassword)}>
                    Save Changes
                  </Button>
                </Form.Item>
                <Row hidden={userData.role > 0 && chosenUser.role > 0}>
                  <Col span={24}>
                    <Card title={chosenUser.job?.title ?? "Loading"} bordered={false}>
                      <p>Median Pay: {chosenUser.job?.pay ?? "Loading"}</p>
                      <p>Description: {chosenUser.job?.description ?? "Loading"}</p>
                    </Card>
                  </Col>
                </Row>
              </Form>
            </div>
            <div className='flexContainer'>
              <Button hidden={userData.role === 0 || chosenUser.role > 0} onClick={handleReset} type="danger">Reset Password</Button>
              <Popconfirm  
                hidden={userData.role === 0}
                onConfirm={handleDelete}
                title={`Are you sure? This student will be permanently deleted`}
                okText="Yes"
                cancelText="No"
              >
                <Button hidden={userData.role === 0 || chosenUser.role > 0} type="danger">Delete User</Button>
              </Popconfirm>
            </div>
          </div>
        );
      }}
    </Formik>
  );
}

function mapStateToProps(state) {
  return {
    data: state
  };
}

export default withRouter(connect(mapStateToProps)(UserPage))
