import {
    LOGIN_USER,
    REGISTER_USER,
    AUTH_USER,
    LOGOUT_USER,
    GET_USERS,
    GET_USER,
    GET_USER_SPECIAL,
    UPDATE_USER,
    SET_ACCOUNT,
    GET_CLASSES,
    GET_CLASS,
    EDIT_CLASSES,
    UPDATE_CLASS,
    KICK_MEMBER,
    GET_AUCTIONS,
    REMOVE_ADMIN,
    ADD_ADMIN,
    TOAST,
    GET_BUCK,
    GET_ACCOUNTS,
    GET_BUCK_TRANSACTIONS,
    GET_TEACHERS,
    UPDATE_TCLASS,
    KICK_TEACHER,
    SEARCH_CRYPTO,
    CRYPTO_ACCOUNTS,
    CRYPTO_HISTORY,
    BUCKS,
    TEACHER_TRANSACTIONS,
    TEACHER_EVENTS,
    SETTINGS,
    GET_MARKET_ITEMS,
    GET_SCHOOL_TEACHERS,
    TEACHER_BUCK,
    STUDENT_COUNT,
    GET_LOTTO_LATEST,
    GET_LOTTO_PREVIOUS,
    SYMBOL_LOOKUP,
    CD_ACCOUNTS,
    CD_TRANSACTIONS,
    CRYPTOS,
    PURCHASES

} from '../_actions/types';
 

export default function reducer(state={}, action){
    switch(action.type){
        case PURCHASES:
            return {...state, purchases: action.payload}
        case TEACHER_BUCK:
            return {...state, teacherBuck: action.payload.value}
        case GET_SCHOOL_TEACHERS:
            return {...state, teachers: action.payload}
        case GET_MARKET_ITEMS:
            return {...state, marketItems: action.payload }
        case REGISTER_USER:
            return {...state, register: action.payload }
        case BUCKS:
            return {...state, bucks: action.payload }
        case CRYPTOS:
            return {...state, cryptos: action.payload }
        case TEACHER_EVENTS:
            return {...state, teacherEvents: action.payload }
        case SETTINGS:
            return {...state, settings: action.payload }
        case TEACHER_TRANSACTIONS:
            return {...state, teacherTrans: action.payload }
        case LOGIN_USER:
            return { ...state, loginSucces: action.payload }
        case AUTH_USER:
            return {...state, userData: action.payload }
        case LOGOUT_USER:
            return {...state }
        case GET_USERS:
            return {...state, usersData: action.payload }
        case GET_USER:
            return {...state, chosenUser: action.payload }
        case GET_USER_SPECIAL:
            return {...state, usersData: [action.payload, ...state.usersData] }
        case UPDATE_USER:
            return {...state, chosenUser: action.payload }
        case SET_ACCOUNT:
            return {...state, chosenAccount: action.payload }
        case GET_CLASSES:
            if (action.payload.message) return state
            return {...state, classes: action.payload }
        case GET_CLASS:
            return {...state, class: action.payload }
        case EDIT_CLASSES:
            return {...state, classes: state.classes.map(function(value, index, arr){
                    if (value._id !== action.payload._id) {
                        return value
                    }
                    else return action.payload
                })
            }
        case UPDATE_CLASS:
            return {...state, class:{ 
                ...state.class,
                addCode: action.payload.addCode
            }}
        case KICK_MEMBER:
            return {...state, class:{
                ...state.class,
                members: state.class.members.filter(function(value, index, arr){ 
                    return value._id !== action.payload.kick_id;
                })
            }}
        case KICK_TEACHER:
            return {...state, teacherClass:{
                ...state.teacherClass,
                members: state.teacherClass.members.filter(function(value, index, arr){ 
                    return value._id !== action.payload.kick_id;
                })
            }}
        case GET_AUCTIONS:
            return {...state, auctions: action.payload}
        case REMOVE_ADMIN:
            return {...state, classes: action.payload}
        case ADD_ADMIN:
            return {...state, classes: action.payload}
        case TOAST:
            return {...state, toast: action.payload}
        case GET_BUCK:
            return {...state, buck: action.payload}
        case GET_ACCOUNTS:
            return {...state, accounts: action.payload}
        case GET_BUCK_TRANSACTIONS:
            return {...state, buckTransactions: action.payload}
        case GET_TEACHERS:
            return {...state, teacherClass: action.payload}
        case UPDATE_TCLASS:
            return {...state, teacherClass:{
                ...state.teacherClass,
                addCode: action.payload.addCode
            }}
        case SEARCH_CRYPTO:
            return {...state, crypto: action.payload}
        case CRYPTO_ACCOUNTS:
            return {...state, cryptoAccounts: action.payload}
        case CRYPTO_HISTORY:
            return {...state, cryptoHistory: action.payload}
        case STUDENT_COUNT:
            return {...state, studentCount: action.payload}
        case GET_LOTTO_LATEST:
            return {...state, lottoLatest: action.payload}
        case GET_LOTTO_PREVIOUS:
            return {...state, lottoPrevious: action.payload}
        case SYMBOL_LOOKUP:
            return {...state, cryptoSymbol: action?.payload ?? ""}
        case CD_ACCOUNTS:
            return {...state, CDAccounts: action.payload}
        case CD_TRANSACTIONS:
            return {...state, CDTransactions: action.payload}
        default:
            return state;
    }
}