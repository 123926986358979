import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { useDispatch, connect } from "react-redux";
import { Space, message, Dropdown, Switch, Radio, Row, Col } from 'antd';
import { selectClass, postTransaction } from "../../../_actions/user_actions";

let netWorth = 100
let sorted = []

function PickerPage(props) {
  const dispatch = useDispatch();
  const userData = props.data.user?.userData ?? []
  let members = props?.user?.class?.members ?? []
  
  if (sorted.length === 0) {
    sorted = [...members.sort((a, b) => a.netWorth - b.netWorth)]
  }

  const averageNetWorths = (arr) => {

    let totalNetWorth = 0;
    let count = 0;
  
    for (const player of arr) {
      // Ensure the "networth" property exists and is a valid number
      if (player.hasOwnProperty("netWorth") && typeof player.netWorth === "number" && player.netWorth > 0) {
        totalNetWorth += player.netWorth;
        count++;
      } // Optional: Handle missing or invalid networths
      else {
        console.warn(`Invalid or missing networth for player: ${player}`);
      }
    }
  
    // Prevent division by zero if no valid networths were found
    return count > 0 ? totalNetWorth / count : 0;
  }

  useEffect(() => {
    netWorth = averageNetWorths(members)
  }, [props.user.class])

  

  const [chosenStudent, setChosenStudent] = useState({
    firstName: "Click",
    lastName: "Here"
  })
  const [replace, setReplace] = useState(true)
  const [pickType, setPickType] = useState("random")

  const chooseStudent = () => {

    let index
    if (pickType === "highs") {
      index = highPicker(sorted)
    }
    else if (pickType === "lows") {
      index = lowPicker(sorted)
    }
    else { //random
      index = Math.floor(Math.random() * sorted.length);    
    }
  
    setChosenStudent(sorted[index])

    if (!replace) {
      sorted = [...sorted.slice(0, index), ...sorted.slice(index + 1)]
    }
    
  }

  const highPicker = (arr) => {
    const lastIndex = arr.length - 1;
    const lastQuarterIndex = Math.floor(lastIndex * 0.75);

    const randomValue = Math.random();

    if (randomValue < 0.75) {
      // Choose from the last 25% of indexes
      return Math.floor(Math.random() * (lastIndex - lastQuarterIndex) + lastQuarterIndex);
    } else {
      // Choose from the first 75% of indexes
      return Math.floor(Math.random() * lastQuarterIndex);
    }
  }

  const lowPicker = (arr) => {
    const firstQuarterLength = Math.floor(arr.length / 4);
    const randomValue = Math.random();

    if (randomValue < 0.75) {
      // 75% chance to pick from first quarter
      return Math.floor(Math.random() * firstQuarterLength);
    } else {
      // 25% chance to pick from the rest of the array
      return Math.floor(Math.random() * (arr.length - firstQuarterLength)) + firstQuarterLength;
    }
  }

  const payClick = (amount, student) => {
    dispatch(postTransaction(
      {
        amount: amount,
        description: "Random Pick",
        owner_id: userData._id,
        student: student
      }
    )).then(response => {
      if (response.payload === "") {
      message.success(amount > 0 ? "Paid" : "Deducted", .5)
      }
      else message.error(response.payload, 3)
    })
  }

  useEffect(() => {
    const path = props.location.pathname
    let n = path.lastIndexOf('/');
    let short_id = path.substring(n + 1);
    let dataToSubmit = {_id : short_id}
  	dispatch(selectClass(dataToSubmit))
  }, [dispatch, props.location.pathname])

  const onChange = (checked) => {
    if (checked) {
      sorted = [...members]
    }
    setReplace(checked)
  };

  const correctClick = (e) => {
    payClick(e.target.outerText * 1.0, chosenStudent._id)
  }

  const incorrectClick = (e) => {
    payClick(e.target.outerText * -1.0, chosenStudent._id)
  }

  const onRadioChange = (e) => {
    setPickType(e.target.value)
  };

  const nameClick = (e) => {
    chooseStudent()
  }
  
  return (
    <Space direction="vertical" size={3}>
      <Dropdown
        placement="topLeft"
        trigger={['click']}
        dropdownRender={(menu) => (
          <div className="dropdown-content">
            <Space.Compact direction="vertical">
              <Switch checkedChildren="Replace" unCheckedChildren="No Replace" defaultChecked style={{backgroundColor: '#4096ff', maxWidth: "60%"}} onChange={onChange} />
              <Radio.Group defaultValue="random" buttonStyle="solid" onChange={onRadioChange}>
                <Radio.Button value="random">Random</Radio.Button>
                <Radio.Button value="highs">highs</Radio.Button>
                <Radio.Button value="lows">lows</Radio.Button>
              </Radio.Group>
            </Space.Compact>
          </div>
        )}
      >
        <a onClick={(e) => e.preventDefault()}>
          Settings
        </a>
      </Dropdown>

      <Row gutter={[4, 4]}>
        <Col>
          <div onClick={nameClick} className="centerText blue pickerStyle">{chosenStudent.firstName + " " + chosenStudent.lastName}</div>
        </Col>
      </Row>
      <Row gutter={[4, 4]}>
        <Col span={12}>
          <div onClick={incorrectClick} className="centerText red">{ Math.floor(netWorth * .01) }</div>
        </Col>
        <Col span={12}>
          <div onClick={correctClick} className="centerText green">{ Math.floor(netWorth * .02) }</div>
        </Col>
      </Row>
    </Space>
  );
}

function mapStateToProps(state) {
  return {
    data: state
  };
}

export default withRouter(connect(mapStateToProps)(PickerPage))
